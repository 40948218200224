import { isMobile as isPhone, template } from '@shein/common-function'
export default {
  namespaced: true,
  state: {
    recommendedSizeData: {
      recommendedSize: null,
      recommendedType: null,
      braRecommendSizes: []
    }
  },
  getters: {
    productIntroData: (state, getters, rootState) => rootState.productIntroData,
    screenAbtConfig: (state, getters, rootState, rootGetters) => rootGetters.screenAbtConfig || {},
    mallCode: (state, getters) => getters.productIntroData?.mallCode,
    tsp: (state, getters) => getters.productIntroData?.tsp,
    language: (state, getters, rootState) => rootState.language,
    findSimilarFloatInfo: (state, getters, rootState) => rootState.SimilarFloat.findSimilarFloatInfo || {},
    skuInfo: (state, getters, rootState) => rootState.skuInfo || {},
    promotionInfo: (state, getters) => getters.productIntroData.detail?.mall?.[getters.mallCode]?.promotionInfo || [],
    externalSkuCode: (state, getters, rootState) => rootState?.externalSkuCode,
    isAllSoldOut: (state, getters) => {
      const { detail } = getters.productIntroData || {}
      const { is_on_sale, stock } = detail || {}
      return +is_on_sale === 0 || +stock === 0
    },
    skuMapCompose: (state, getters, rootState) => {
      const { productInfo } = rootState.hotModules || {}
      const { skuMapCompose: hotSkuMapCompose } = productInfo || {}
      const { skuMapCompose } = getters.productIntroData || {}
      return Object.assign({}, skuMapCompose || {}, hotSkuMapCompose || {})
    },
    dataMapCompose: (state, getters) => {
      const { productIntroData, skuMapCompose } = getters
      const { sizeFormatData } = productIntroData || {}
      const { dataMapCompose } = sizeFormatData || {}
      const { dataMap, dataIdMap } = dataMapCompose || {}
      const { skuAllInfoMap, skuAllInfo } = skuMapCompose || {} 
      Object.keys(skuAllInfoMap || {}).forEach(skuCode => {
        const { key, idKey } = skuAllInfoMap[skuCode] || {}
        const stock = skuAllInfo?.[skuCode]?.stock
        if (stock !== undefined) {
          dataMap[key] = stock
          dataIdMap[idKey] = stock
        }
      })
      return dataMapCompose
    },
    catIdsMap: (state, getters) => {
      const { productIntroData } = getters
      const parentCats = productIntroData?.parentCats || []
      if (!parentCats?.cat_id) return {}
      const result = {}
      const arr = [parentCats]
      for(let item of arr) {
        const { cat_id, children } = item || {}
        result[cat_id] = 1
        if (children?.length) {
          arr.push(...children)
        }
      }
      return result
    },
    sizeComposeData: (state, getters, rootState, rootGetters) => {
      const { SiteUID } = rootState
      const { productIntroData, language, skuMapCompose, dataMapCompose } = getters
      const { isPaidUser } = rootGetters
      const { sizeFormatData, sizeInfoDes, multiLocalSize, detail, getOtherOptions, apolloConfig, siteMallInfo } = productIntroData || {}
      const { complianceMode = false, complianceTipsMode = false, isHitComplianceMode } = apolloConfig || {}
      const { saleAttrList, tackInfoDes, relatedLocalSize } = sizeFormatData || {}
      const { GB_cssRight, IS_RW, langPath } = gbCommonInfo || {}
      const constant = {
        cssRight: GB_cssRight, 
        isRw: IS_RW, 
        langPath,
        siteUid: SiteUID,
        isPaidUser,
        complianceTipsMode,
        complianceMode,
        isHitComplianceMode,
      }
      const baseInfo = { ...detail }
      baseInfo.mall_info.forEach(item => {
        const siteMall = siteMallInfo?.find?.(site => site.mall_code === item.mall_code)
        // 混入mall tag，用于判断是本地仓还是跨境仓，最后拿来上报
        if (siteMall) {
          item.mall_tags = siteMall.mall_tags
          item.mall_sort = siteMall.mall_sort
        }
      })
      return {
        language,
        sizeInfoDes,
        localSize: multiLocalSize,
        skuMapCompose,
        baseInfo,
        saleAttrList,
        dataMapCompose,
        tackInfoDes,
        relatedLocalSize,
        constant,
        getOtherOptions,
      }
    },
    sizeConfig: (state, getters, rootState, rootGetters) => {
      const { pageComponents, screenAbtTspConfig } = rootGetters || {}
      const { DETAIL_STREAM_PART } = rootState
      const { checkNotSelectedTips } = rootState.AddBtn || {}
      const { productIntroData, screenAbtConfig, catIdsMap } = getters
      const { sizeFormatData, apolloConfig, showMallSite, detail, localSizeList } = productIntroData || {}
      const { OPEN_DETAIL_SIZE_IDMAP, sizeTipsConfig, complianceMode, isHitComplianceMode } = apolloConfig || {}
      const { heightCatIds, showTaxation } = sizeTipsConfig || {}
      const { goodsdetailmall, sizeguidefit, defaultlocalsize, goodsSizeRelated, longsize, mallchange, goodsMainAttPicShow, goodsdetailTips, EstimatedPrice } = screenAbtConfig || {}
      const { goodsSizeGroupRelated } = screenAbtTspConfig || {}
      const { country_code } = localSizeList || {}
      const longsizeAbt = longsize?.p?.longsize
      const goodsSizeGroupRelatedValue = goodsSizeGroupRelated?.param?.goodsSizeGroupRelated
      const shieldOneSizeCatId = [1864, 2032, 2273, 3195, 3634, 3637, 3657]
      const hideOneSize = shieldOneSizeCatId.some(item => !!catIdsMap[item])
      // sizeGroup添加cv实验
      const sizeRelatedTest = productIntroData?.tsp?.[100000317] == '1' ? (goodsSizeRelated?.param?.sizerelatedtest || 'only_manual') : ''
      // 隐藏童装大小码
      const hideKidRelatedSize = productIntroData?.tsp?.[100000264] == '1' || productIntroData?.tsp?.[100000303] == '1'
      // 隐藏部分 quickship 场景的显示
      const hiddenPartialQuickShip = false
      // 当用户选择本地化尺码，且同时展示默认尺码时，弱化括号内默认尺码展示, 默认不是弱化
      const shouldWeakenDefaultSize = defaultlocalsize?.p?.localstrong === 'on'
      // 是否自动切换mall
      const isAutoSwitchMall = goodsdetailmall?.param?.mallautoswitch === 'on' && DETAIL_STREAM_PART.THIRD
      const showSizeGuideInTips = sizeFormatData?.showSizeGuide && pageComponents?.sizeGuideConf?.isShow
      const showSizeFeedBack = ['1888', '2030'].some(item => !!catIdsMap[item])
      const showOnlyOneLocalSize = ['on', 'new_on'].includes(defaultlocalsize?.param?.onlyonelocalsize) && !!country_code
      const onlyOneLocalSizeNewOn = defaultlocalsize?.param?.onlyonelocalsize === 'new_on'
      const swapSizeTabPosition = defaultlocalsize?.param?.size_tab_sort === 'new' && defaultlocalsize?.param?.defaultlocalsize === 'old'
      // 强化s3会员价格
      const isS3newPrice = EstimatedPrice?.param?.S3newprice === 'new'

      return {
        from: 'detail',
        eventClickId: '1-8-6-5',
        localSizeList,
        isMobile: typeof window !== 'undefined' ? !!isPhone() : false,
        showSizeGuideInTips,
        sizeGuideInTitle: false,
        sizeSaleAttrId: sizeFormatData?.sizeSaleAttrId,
        checkNotSelectedTips,
        isNewSizeLocal: true,
        showHightSize: heightCatIds?.some?.(item => !!catIdsMap[item]),
        longsizeTowLine: ['twofold', 'twounfold'].includes(longsizeAbt),
        openFoldSizes: longsizeAbt && !['oneunfold', 'twounfold'].includes(longsizeAbt),
        showSizeFeedBack,
        hideKidRelatedSize,
        sizeRelatedTest,
        sizeGroupPositionAbt: ['mid', 'bottom', 'fold', 'size'].includes(goodsSizeGroupRelatedValue) && !hideKidRelatedSize ? goodsSizeGroupRelatedValue : '',
        hideOneSize,
        showTaxation,
        showMallSite: showMallSite && detail?.mall_info?.length > 0,
        openAutoCheckMall: mallchange?.param?.mallshow === 'auto',
        showAttrImgPopover: goodsMainAttPicShow?.param?.goodsMainAttPicShow === 'on',
        complianceMode,
        isHitComplianceMode,
        OPEN_DETAIL_SIZE_IDMAP,
        sizepriority: 'off',
        defaultlocalsize: defaultlocalsize?.param?.defaultlocalsize || 'old',
        showOnlyOneLocalSize,
        onlyOneLocalSizeNewOn,
        swapSizeTabPosition,
        sizeguidefit: sizeguidefit?.param?.sizeguidefit || 'off',
        hiddenPartialQuickShip,
        shouldWeakenDefaultSize,
        isAutoSwitchMall,
        tipsshow: goodsdetailTips?.param?.tipsshow,
        isS3newPrice
      }
    },
    findSimilarFloatParams: (state, getters) => { 
      const { language, productIntroData, skuInfo, findSimilarFloatInfo } = getters || {}
      return {
        isQuickAdd: false,
        language,
        productIntroData,
        skuInfo,
        update: findSimilarFloatInfo?.show,
        ...(findSimilarFloatInfo || {}),
      }
    },
    tipsCombo: (state, getters, rootState, rootGetters) => {
      const { pageComponents } = rootGetters || {}
      const { SiteUID, newFlashPromotion, cccConfig, showHeelHeight, cmInchInfo, heelHeight, hotModules } = rootState || {} 
      const { productIntroData, promotionInfo, screenAbtConfig, language, catIdsMap } = getters || {} 
      const { SHEIN_KEY_PC_25123, SHEIN_KEY_PC_25124, SHEIN_KEY_PC_28172 } = language || {}
      const { detail, apolloConfig, sizeFormatData } = productIntroData || {}
      const commentInfo = hotModules.comment.commentInfo || {}
      const { saleAttrList } = sizeFormatData || {}
      const { polyAttrSize } = saleAttrList || {}
      const { tips, isReady } = cccConfig || {}
      const { onlyxleft, goodsdetailTips, outofstocktips } = screenAbtConfig || {}
      const { max: defaultMax, min: defaultMin, offsetSizeConfig = [], catIds, trueToSizeStrongCatId } = apolloConfig?.sizeTipsConfig || {}
      // 从apolloConfig中获取当前站点的最大最小值，若该站点没有配置则使用默认值
      const { max = defaultMax, min = defaultMin } = offsetSizeConfig.find(item => {
        const { SiteUID: ids } = item || {}
        return ids?.includes?.(SiteUID)
      }) || {}

      let flashSaleInfo, underPriceInfo
      promotionInfo?.forEach?.(item => {
        const { typeId } = item || {}
        if (typeId == 10) flashSaleInfo = item
        if (typeId == 30) underPriceInfo = item
      })
      // 评论尺码真实提示相关数据
      let trueToSizeStrongText = ''
      const defaultTrueToSizeText = tips || detail?.productDetails?.find(item => String(item.attr_id) == '146')?.attr_value || ''
      const true_size = commentInfo?.percent_overall_fit?.true_size || ''
      const true_size_num = Math.round(+true_size?.replace?.('%', '') || 0)
      const comment_num = commentInfo?.comment_num || 0

      const showTrueToSizeStrong = polyAttrSize?.length && true_size_num >= 90 && comment_num >= 10 && trueToSizeStrongCatId?.some?.(item => catIdsMap[item])
      if (!tips && showTrueToSizeStrong) {
        trueToSizeStrongText = template('', SHEIN_KEY_PC_28172)
      }
      // 评论尺码偏大偏小提示
      let sizeTipsText = ''
      const { size_tips: sizeTips = {} } = commentInfo || {}
      const large =  Number(sizeTips?.large?.replace?.('%', '') || '')
      const small =  Number(sizeTips?.small?.replace?.('%', '') || '')
      const isHitCatId = catIds?.some?.(item => catIdsMap[item])
      const isExceptionSize = !tips && (large >= max || small >= max) && Math.abs(large - small) >= min && isReady
      const isLargeSizeTips = large >= max

      if (!tips && isHitCatId && isExceptionSize) {
        sizeTipsText = (isLargeSizeTips ? SHEIN_KEY_PC_25123 : SHEIN_KEY_PC_25124)
      }
      // 是否隐藏 tips 后面的跟高显示的 abt
      const hiddenHeelHeightTipsAbt = goodsdetailTips?.param?.showheel === 'off'
      const cccTrueToSizeText = tips || ''

      const { detailsonlynleft = 10, detailsalmostsoldout = 14 } = outofstocktips?.param || {}
      // 低库存阈值
      const lowInventoryThreshold = detailsonlynleft !== 'off' ? Number(detailsonlynleft) || 10 : 10
      // 可售天数阈值(取不到则默认为-1, 即永远不为相对低库存)
      const almostSoldOutThreshold = detailsalmostsoldout !== 'off' ? Number(detailsalmostsoldout) || -1 : -1
      // 模糊库存阈值
      const fuzzyInventoryThreshold = lowInventoryThreshold < 50 ? lowInventoryThreshold : 50

      // 低库存提示
      return {
        newFlashPromotionData: productIntroData?.newFlashPromotion || newFlashPromotion || {},
        flashSaleInfo,
        underPriceInfo,
        soldOutTipsLimitNum: onlyxleft?.p?.onlyxleft === 'ten' ? 10 : 5,
        showSizeStockTips: !pageComponents?.qtyConf?.isShow,
        defaultTrueToSizeText,
        isLargeSizeTips,
        sizeTipsText,
        showHeelHeight,
        trueToSizeText: true_size,
        trueToSizeStrongText,
        cmInchInfo,
        heelHeight,
        sizeTipsBold: true,
        cccTrueToSizeText,
        hiddenHeelHeightTipsAbt,
        lowInventoryThreshold,
        almostSoldOutThreshold,
        fuzzyInventoryThreshold
      }
    },
    sizeRecommendData: (state, getters) => {
      const { screenAbtConfig, productIntroData } = getters
      const { Selfsizerecommended } = screenAbtConfig || {}
      const { rule_id, rule_type, sizeFormatData } = productIntroData || {}
      const { oneSizeStatus } = sizeFormatData || {}
      // 1 自有推荐 2 第三方推荐推荐
      let sizeRecommendType = Selfsizerecommended?.param !== 'type=C' ? 
        (Selfsizerecommended?.param == 'type=A' && rule_id != 0 ? 1 : 2)
        : ''
      // RW不接入第三方推荐
      const { IS_RW } = gbCommonInfo || {}
      if (sizeRecommendType === 2 && IS_RW) sizeRecommendType = ''

      return {
        showSelfRecommend: sizeRecommendType === 1 && !oneSizeStatus,
        sizeRecommendType,
        selfRecommendType: rule_id != 0 ? rule_type : '',
      }
    },
    sizeModalComb: (state, getters, rootState, rootGetters) => {
      const { productIntroData } = getters
      const { isPaidUser } = rootGetters
      const { sizeFormatData, getOtherOptions } = productIntroData || {}
      const { saleAttrList } = sizeFormatData || {}
      const { skcSaleAttr, skuList } = saleAttrList || {}
      return {
        saleAttrList: {
          skc_sale_attr: skcSaleAttr,
          sku_list: skuList,
        },
        relatedGoodSizes: getOtherOptions?.[0] || {},
        productIntroData: {
          isPaidUser,
          multiLocalSize: productIntroData.multiLocalSize,
          detail: productIntroData.detail,
          sizeInfoDes: productIntroData.sizeInfoDes,
          localSizeList: productIntroData.localSizeList,
          rule_type: productIntroData.rule_type,
          rule_id: productIntroData.rule_id,
          cccAttrGroups: productIntroData.cccAttrGroups
        }
      }
    },
    tipsReady: (state, getters, rootState) => {
      return rootState.DETAIL_STREAM_PART.THIRD
    }
  },
  mutations: {
    // 更新推荐尺码
    updateRecommendedSizeData (state, payload) {
      state.recommendedSizeData = {
        ...payload
      }
    }
  }
}
